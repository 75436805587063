"use strict";

var closest = function(el, fn) {
    return el && (fn(el) ? el : closest(el.parentNode, fn));
}

function cssPropertyValueSupported(prop, value) {
  var d = document.createElement('div');
  d.style[prop] = value;
  return d.style[prop] === value;
}

var stubtonhall = {
  checkJavascriptEnabled: null,
  menuToggle: null,
  galleryGrid: null,
  photostrip: null,
  slideshow: null,
  faqExpand: null,
  submitContactForm: null,
  heroVideo: null,
  trackDownloads: null,
  init: function() {
    this.checkJavascriptEnabled(),
    this.menuToggle(),
    this.galleryGrid(),
    this.photostrip(),
    this.slideshow(),
    this.faqExpand(),
    this.submitContactForm(),
    this.heroVideo()
    this.trackDownloads()
  },

  /*  Check JS  */

  checkJavascriptEnabled: function() {
    document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, '') + ' js ';
  },


  /*  Menu toggle  */

  menuToggle: function() {

    var menuToggle = document.querySelector('.js-masthead__toggle');
    var menu = document.querySelector('.c-masthead');
    var nav = document.querySelector('.c-masthead__nav');

    var toggleMenu = function(e) {
      e.preventDefault();
      document.documentElement.classList.toggle('has-masthead-open');
    }

    var openMenu = function(e) {
      e.preventDefault();
      document.documentElement.classList.add('has-masthead-open');
    }

    var closeMenu = function(e) {
      e.preventDefault();
      document.documentElement.classList.remove('has-masthead-open');
    }

    menuToggle.addEventListener('click', toggleMenu);

    document.onkeydown = function(evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
        isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        document.documentElement.classList.remove('has-masthead-open');
      }
    };


  },

  /* Gallery Grid */
  galleryGrid: function() {
      if (document.querySelector('.c-gallery')) {
          //var $grid = $('.grid').imagesLoaded( function() {
          var grid = document.querySelector('.c-gallery');
          var pckry = new Packery( grid, {
              initLayout: false,
              columnWidth: '.c-gallery__item',
              itemSelector: '.c-gallery__item',
              percentPosition: true,
              transitionDuration: 0
          });

          //});
          pckry.on('layoutComplete', function() {
              console.log('layout is complete');
              grid.classList.add('c-gallery--loaded');
          });
          // trigger initial layout
          pckry.layout();
      }
  },

  /* Photostrip */


  photostrip: function() {

      if (document.querySelector('.c-photostrip')) {
      // test if no-touch and larger than 767
      var strips = document.querySelectorAll('.c-photostrip');

      strips.forEach((strip) => {
        var flkty = new Flickity( strip, {
            itemSelector: 'figure',
            // cellAlign: (window.innerWidth > 768) ? 'left' : 'left',
            cellAlign: 'center' ,
            groupCells: true,
            imagesLoaded: true,
            percentPosition: false,
            lazyLoad: 2,
            pageDots: false,
            contain: true
        });
        strip.classList.add('loaded');
      })
    }
  },


  /* Slideshow */

  slideshow: function () {
    if (document.querySelector('.js-carousel')) {
    var carousel = document.querySelector('.js-carousel');
    var flkty = new Flickity( carousel, {
        itemSelector: '.c-hero__carousel-item',
        prevNextButtons: false,
        pageDots: false,
        autoPlay: 3000,
        pauseAutoPlayOnHover: false,
        fade: true,
        draggable: false
    });
    }
  },

  /* Track downloads: sends event to Google Analytics */
  trackDownloads: function() {

    if ((document.querySelector('.js-download')) && (typeof ga == 'function')) {
      var links = document.querySelectorAll('.js-download');
      links.forEach(function(link) {
        var name = link.getAttribute('data-name');
        link.addEventListener('click', function() {
          ga('send', 'event', 'File', 'download', name);
        });
      });
    }
  },

  /* Blog sticky sidebar */

  blogSidebarSticky: function() {

    if (document.querySelector('#sidebar')) {
      var sidebar = new StickySidebar('#sidebar', {
        containerSelector: '#main-content',
        minWidth: 768,
        topSpacing: 20,
        bottomSpacing: 20
      });
    }
  },

  heroVideo: function() {

    if (!CSS.supports('object-fit: cover')) {

    var video = document.getElementById('hero-video');

    var videoHeight,
      videoWidth,
      newVideoHeight,
      newVideoWidth,
      containerHeight,
      containerWidth;

    videoHeight = newVideoHeight = video.offsetHeight;
    videoWidth = newVideoWidth = video.offsetWidth;

    containerHeight = video.parentNode.offsetHeight;
    containerWidth = video.parentNode.offsetWidth;
    var containerRatio = containerHeight / containerWidth;

    var aspectRatio = 1080 / 2540 ;

    if ( aspectRatio < containerRatio ) {
      newVideoHeight = containerHeight;
      newVideoWidth = containerHeight / aspectRatio ;
    }

    if ( aspectRatio >= containerRatio ) {
      newVideoHeight = containerHeight;
      newVideoWidth = newVideoHeight * aspectRatio;
    }

    video.style.height = newVideoHeight + 'px';
    video.style.width = newVideoWidth + 'px';

    console.log('videoHeight: '+ videoHeight + ', videoWidth:' + videoWidth);
    console.log('aspectRatio: '+ aspectRatio + ', containerRatio:' + containerRatio);
    console.log('H: '+ newVideoHeight + ', W: '+ newVideoWidth)

    video.style.top = 0 + (( containerHeight - newVideoHeight ) / 2 ) + 'px';
    video.style.left = 0 + (( containerWidth - newVideoWidth ) / 2 ) + 'px';

    }
  },

  faqExpand: function() {

    var buttonToggles =  document.querySelectorAll('button[aria-expanded][aria-controls]');

    var hideContent = function (target) {
      var t = document.querySelector('#'+target);
      t.setAttribute('hidden',true);
    };

    var showContent = function (target) {
      var t = document.querySelector('#'+target);
      t.setAttribute('hidden',false);
    };

    buttonToggles.forEach(function(element) {
      var target = element.getAttribute('aria-controls')
      element.addEventListener('click', function() {
        // If closed open,
        if (this.getAttribute('aria-expanded') === 'true') {
          this.setAttribute('aria-expanded', 'false');
          hideContent(target);
        }
        else {
          this.setAttribute('aria-expanded', 'true');
          showContent(target);
        }

      });
    });
  },

  submitContactForm: function () {

    var safari = navigator.userAgent.indexOf("Safari") > -1

    function ajaxifyForm(event) {
      var form = event.target
      var data = new FormData(form)
      var request = new XMLHttpRequest()

      if (safari) {
        for (var i = 0; i < form.elements.length; i++) {
          if (form.elements[i].type == "file") {
            if (form.elements[i].value == "") {
              var elem = form.elements[i]
              data.delete(elem.name)
            }
          }
        }
      }

      var method = form.getAttribute("method")
      var action = form.getAttribute("action")

      request.open(method, action ? action : window.location.href, true)
      request.setRequestHeader("Cache-Control", "no-cache")
      request.setRequestHeader("X-Requested-With", "XMLHttpRequest")
      request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest")
      request.onload = function() {
        removeMessages(form)

        if (request.status === 200) {
          var response = JSON.parse(request.response)

          if (response.success) {
            // Reset the form so that the user may enter fresh information
            form.reset()

            // ============================================================
            // Uncomment this to have the form redirect to the success page
            // ============================================================
            // if (response.return) {
            //   window.location.href = response.return;
            // }

            renderFormSuccess(form)

          } else if (response.errors || response.formErrors) {
            renderErrors(response.errors, form)
            renderFormErrors(response.formErrors, form)
          }

          replaceInputs(form, response);
          unlockSubmit(form)
        } else {
          console.error(request)
        }

        unlockSubmit(form)
      }

      request.send(data)
      event.preventDefault()
    }

    function replaceInputs(form, response) {
      if (response.duplicateCheck) {
        var prefix = response.duplicateCheck.prefix;
        var value = response.duplicateCheck.value;
        var input = form.querySelector('input[name^="' + prefix + '"]');
        if (input) {
          input.setAttribute("name", value);
          input.setAttribute("value", value);
        }
      }
    }

    /**
      * Remove the "disabled" state of the submit button upon successful submit
      *
      * @property form
      */
    function unlockSubmit(form) {
      form.querySelector("button[type=submit]").removeAttribute("disabled")
      if (typeof grecaptcha !== "undefined") {
        grecaptcha.reset()
      }
    }

    function removeMessages(form) {
      // Remove error messages
      form.querySelectorAll("ul.errors").remove()
      var fieldsWithErrors = form.querySelectorAll(".has-error")
      for (var fieldIndex = 0; fieldIndex < fieldsWithErrors.length; fieldIndex++) {
        fieldsWithErrors[fieldIndex].classList.remove("has-error")
      }

      // Remove success messages
      form.querySelectorAll(".form-success").remove()
      document.getElementsByClassName("form-errors").remove()
    }

    function renderFormSuccess(form) {
      var successMessage = document.createElement("div")
      successMessage.classList.add("c-message", "c-message--success", "c-message--with-background", "c-message--centered", "form-success")

      successMessage.appendChild(
        document.createTextNode("Thank you for your enquiry.")
      )

      form.insertBefore(successMessage, form.childNodes[0])
    }

    function renderErrors(errors, form) {
      for (var key in errors) {
        if (!errors.hasOwnProperty(key) || !key) {
          continue
        }

        var messages = errors[key]
        var errorsList = document.createElement("ul")
        errorsList.classList.add("errors")

        for (var messageIndex = 0; messageIndex < messages.length; messageIndex++) {
          var message = messages[messageIndex]
          var listItem = document.createElement("li")
          listItem.appendChild(document.createTextNode(message))
          errorsList.appendChild(listItem)
        }

        var inputList = form.querySelectorAll("*[name=" + key + "], *[name='" + key + "[]']")
        for (var inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
          var input = inputList[inputIndex]

          input.findAncestor("form-group").classList.add("has-error")
          input.findAncestor("form-group").appendChild(errorsList)
        }
      }
    }

    function renderFormErrors(errors, form) {
      var errorBlock = document.createElement("div")
      errorBlock.classList.add("alert", "alert-danger", "form-errors")

      errorBlock.appendChild(
        document.createTextNode("Error! Please review the form and try submitting again.")
      )

      if (errors.length) {
        var errorsList = document.createElement("ul")
        for (var messageIndex = 0; messageIndex < errors.length; messageIndex++) {
          var message = errors[messageIndex]
          var listItem = document.createElement("li")

          listItem.appendChild(document.createTextNode(message))
          errorsList.appendChild(listItem)
        }

        errorBlock.appendChild(errorsList)
      }

      form.insertBefore(errorBlock, form.childNodes[0])
    }

    // Add remove prototypes
    Element.prototype.remove = function() {
      this.parentElement.removeChild(this)
    }

    Element.prototype.findAncestor = function(className) {
      var el = this
      while (!el.classList.contains(className)) {
        el = el.parentElement
      }

      return el
    }

    NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
      for (var i = this.length - 1; i >= 0; i--) {
        if (this[i] && this[i].parentElement) {
          this[i].parentElement.removeChild(this[i])
        }
      }
    }

    if (document.getElementById("ajax-form")) {
      document.getElementById("ajax-form").addEventListener("submit", ajaxifyForm)
    }

  }
}

document.addEventListener("DOMContentLoaded", function() {
  document.addEventListener("touchstart", function() {}, true);
  stubtonhall.init();
});


(function() {

    var initPhotoSwipeFromDOM = function(gallerySelector) {

        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        var parseThumbnailElements = function(el) {
            var thumbElements = el.childNodes,
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                size,
                item;

            for (var i = 0; i < numNodes; i++) {

                figureEl = thumbElements[i]; // <figure> element

                // include only element nodes
                if (figureEl.nodeType !== 1) {
                    continue;
                }

                linkEl = figureEl.children[0]; // <a> element

                size = linkEl.getAttribute('data-size').split('x');
                medSize = linkEl.getAttribute('data-med-size').split('x');

                // create slide object
                item = {
                    mediumImage: {
                        src: linkEl.getAttribute('data-med'),
                        w: parseInt(medSize[0], 10),
                        h: parseInt(medSize[1], 10)
                    },
                    originalImage: {
                        src: linkEl.getAttribute('href'),
                        w: parseInt(size[0], 10),
                        h: parseInt(size[1], 10)
                    }
                };

                if (figureEl.children.length > 1) {
                    // <figcaption> content
                    item.title = figureEl.children[1].innerHTML;
                }

                if (linkEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = linkEl.children[0].getAttribute('src');
                }

                item.el = figureEl; // save link to element for getThumbBoundsFn
                items.push(item);
            }

            return items;
        };

        // find nearest parent element
        var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function(e) {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : e.returnValue = false;

            var eTarget = e.target || e.srcElement;

            // find root element of slide
            var clickedListItem = closest(eTarget, function(el) {
                return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
            });

            if (!clickedListItem) {
                return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            var clickedGallery = clickedListItem.parentNode,
                childNodes = clickedListItem.parentNode.childNodes,
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index;

            for (var i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue;
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }



            if (index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe(index, clickedGallery);
            }
            return false;
        };

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        var photoswipeParseHash = function() {
            var hash = window.location.hash.substring(1),
                params = {};

            if (hash.length < 5) {
                return params;
            }

            var vars = hash.split('&');
            for (var i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue;
                }
                var pair = vars[i].split('=');
                if (pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            return params;
        };

        var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
            var pswpElement = document.querySelectorAll('.pswp')[0],
                gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement);

            // define options (if needed)
            options = {

                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                getThumbBoundsFn: function(index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                        rect = thumbnail.getBoundingClientRect();

                    return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width
                    };
                }

            };
            options.mainClass = 'pswp--minimal--dark';
            options.barsSize = {
                top: 0,
                bottom: 0
            };
            options.captionEl = true;
            options.fullscreenEl = false;
            options.shareEl = false;
            options.bgOpacity = 0.85;
            options.tapToClose = true;
            options.tapToToggleControls = false;
            options.showHideOpacity = true;
            // options.getThumbBoundsFn = false;

            // PhotoSwipe opened from URL
            if (fromURL) {
                if (options.galleryPIDs) {
                    // parse real index when custom PIDs are used
                    // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                    for (var j = 0; j < items.length; j++) {
                        if (items[j].pid == index) {
                            options.index = j;
                            break;
                        }
                    }
                } else {
                    // in URL indexes start from 1
                    options.index = parseInt(index, 10) - 1;
                }
            } else {
                options.index = parseInt(index, 10);
            }

            // exit if index not found
            if (isNaN(options.index)) {
                return;
            }

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);

            // create variable that will store real size of viewport
            var realViewportWidth,
                useLargeImages = false,
                firstResize = true,
                imageSrcWillChange;

            // beforeResize event fires each time size of gallery viewport updates
            gallery.listen('beforeResize', function() {
                // gallery.viewportSize.x - width of PhotoSwipe viewport
                // gallery.viewportSize.y - height of PhotoSwipe viewport
                // window.devicePixelRatio - ratio between physical pixels and device independent pixels (Number)
                //                          1 (regular display), 2 (@2x, retina) ...


                // calculate real pixels when size changes
                realViewportWidth = gallery.viewportSize.x * window.devicePixelRatio;

                // Code below is needed if you want image to switch dynamically on window.resize

                // Find out if current images need to be changed
                if (useLargeImages && realViewportWidth < 1000) {
                    useLargeImages = false;
                    imageSrcWillChange = true;
                } else if (!useLargeImages && realViewportWidth >= 1000) {
                    useLargeImages = true;
                    imageSrcWillChange = true;
                }

                // Invalidate items only when source is changed and when it's not the first update
                if (imageSrcWillChange && !firstResize) {
                    // invalidateCurrItems sets a flag on slides that are in DOM,
                    // which will force update of content (image) on window.resize.
                    gallery.invalidateCurrItems();
                }

                if (firstResize) {
                    firstResize = false;
                }

                imageSrcWillChange = false;

            });


            // gettingData event fires each time PhotoSwipe retrieves image source & size
            gallery.listen('gettingData', function(index, item) {

                // Set image source & size based on real viewport width
                if (useLargeImages) {
                    item.src = item.originalImage.src;
                    item.w = item.originalImage.w;
                    item.h = item.originalImage.h;
                } else {
                    item.src = item.mediumImage.src;
                    item.w = item.mediumImage.w;
                    item.h = item.mediumImage.h;
                }

                // It doesn't really matter what will you do here,
                // as long as item.src, item.w and item.h have valid values.
                //
                // Just avoid http requests in this listener, as it fires quite often

            });


            // Note that init() method is called after gettingData event is bound
            gallery.init();
        };

        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(gallerySelector);

        for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = photoswipeParseHash();
        if (hashData.pid && hashData.gid) {
            openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
        }
    };

    // execute above function
    initPhotoSwipeFromDOM('.c-blog__gallery');
    initPhotoSwipeFromDOM('.c-gallery');
    initPhotoSwipeFromDOM('.c-photostrip');

})();
